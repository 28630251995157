// Navigation breakpoints
.desktop-menu, .top-bar-left {
  @include show-for(medium);
}

#mobile-menu, .title-bar {
  @include hide-for(medium);
}

// Mobile menu
.off-canvas {
  > ul.menu {
    height: 100vh;
    padding: 1rem;

    a {
      color: $white;
      font-weight: 600;
      font-size: rem-calc(18);
      opacity: .75;
    }

    .is-accordion-submenu-parent > a::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 6px;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: $white transparent transparent;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: 1rem;
      }
  }

  .menu .active > a { background-color: lighten($outer-space, 10%); }


}

.title-bar-title {
  a {
    font-size: 1rem;
    color: #B9B9B9;
  }
}

// Tablet and desktop menu
.top-bar {
  .menu .home a {
    font-size: 1rem;
    color: #B9B9B9;
  }

  .menu a {
    color: #e6e6e6;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1;

    &:hover:not(.button) {
      background-color: #42525D;
    }
  }

  .menu .active > a { background-color: lighten($outer-space, 10%); }

  .menu>li:not(.menu-text)>a { padding: 1rem; }
  .menu li:not(:last-child) { border-right: 1px solid #4e4e4e; }

  .dropdown.menu .submenu { border: 0; }
  .dropdown.menu .has-submenu.is-down-arrow a { padding-right: 1rem; }
  .dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }
  .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after { display: none; }
}

// Basic styles for post navigation. Nicolas Gallagher's "Micro clearfix"
// https://css-tricks.com/snippets/css/clear-fix/
.post-navigation:before,
.post-navigation:after {
  content: "";
  display: table;
}

.post-navigation:after {
  clear:both;
}

.nav-previous { float:left; }
.nav-next { float:right; }
