.flex-card-example {
  @include breakpoint(small) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(medium) {
    flex-direction: row;
  }

  .card {

    @include breakpoint(small) {
      width: 100%;
      img { width: 100%; }
    }

    @include breakpoint(medium) {
      width: 32%;
    }

    .card-content {
      padding: 1rem;
    }

  }
}
